import React, {useEffect} from 'react'
import '../../src/styles/reset.css'
import '../../src/styles/project.css'
import Layout from '../components/layout'

const IndexPage = () => {

  useEffect(() => {

  })

  return (
    <Layout>
      <main>
        <header>
          <h1>Danielle Morales, LEP</h1>
          <div className="flex-image">
            <div className="image">
              <img src="/dm_square.jpg" />
            </div>
            <div className="tagline">
              Independent Educational Evaluation & Consultation Services in the San Francisco Bay Area
            </div>
          </div>
        </header>
        <div className="body-column">
          <div className="header-box">
            <h2>About me</h2>
            <div className="header-line" />
          </div>
          <div className="quad-box">
            <div className="inner-quad">
              <h3>Background</h3>
              <p>Danielle Morales has been a school psychologist since 2014. She is a Licensed Educational Psychologist in California (LEP #4035) and a Nationally Certified School Psychologist (NCSP), in addition to holding her Pupil Personnel Services Credential (PPS).</p>
            </div>
            <div className="inner-quad">
              <h3>Experience</h3>
              <p>Danielle has extensive experience with IEEs and psycho-educational evaluations. She provides consultation regarding IDEA and Section 504, and has helped school-aged clients learn skills to manage difficulties pertaining to emotional challenges, attention/executive functioning, adaptive daily living, and social skills. Prior to working in private practice, Danielle worked as a school psychologist throughout the Los Angeles and Bay Area.</p>
            </div>
            <div className="inner-quad">
              <h3>Specialities</h3>
              <ul>
                <li>Psycho-educational assessment of learning disabilities, ADHD, Autism Spectrum Disorder, and socio-emotional challenges.</li>
                <li>Executive functioning disorders.</li>
                <li>Post-Secondary Assessment.</li>
                <li>Cognitive Behavioral Therapy (CBT) for school related challenges.</li>
              </ul>
            </div>
            <div className="inner-quad">
              <h3>Education</h3>
              <ul>
                <li>SUNY Plattsburgh, Master's Degree & Certificate of Advanced Study (MA/CAS).</li>
                <li>SUNY Plattsburgh, Bachelor of Art, Psychology.</li>
              </ul>
            </div>
          </div>
          <div className="header-box">
            <h2>Contact me</h2>
            <div className="header-line" />
          </div>
          <div className="quad-box">
            <div className="inner-quad">
              <p>Now accepting new clients through the Bylund Clinic. Please call <a href="tel:925-418-4661">(925) 418-4661</a> for inquiries.</p>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default IndexPage
