/* Layout wraps all pages so updates here effect everything */

import React, { Fragment, useEffect } from 'react'
import { Helmet } from 'react-helmet'

const site_title = "Danielle Morales, LEP"
const site_desc = `Danielle Morales has been a school psychologist since 2014. She is a Licensed Educational Psychologist in California (LEP #4035) and a Nationally Certified School Psychologist (NCSP), in addition to holding her Pupil Personnel Services Credential (PPS).`
const site_url = "https://www.daniellemoraleslep.com/"

const Layout = ({children}) => {
  return (
    <Fragment>
      <Helmet>
        <title>{site_title}</title>
        <meta name="description" content={site_desc} />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="canonical" href={ site_url } />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={site_title} />
        <meta
          name="twitter:url"
          content={site_url}
        />
        {/* <meta name="twitter:image" content={IMAGE} /> */}
        <meta name="twitter:description" content={site_desc} />

        <meta property="og:type" content="article" />
        <meta property="og:title" content={site_title} />
        {/* <meta property="og:site_name" content={thisBrand.attributes.siteName} /> */}
        <meta property="og:url" content={site_url}/>
        {/* <meta property="og:image" content={IMAGE} /> */}
        <meta property="og:description" content={site_desc} />

        <script data-schema="NewsArticle" type="application/ld+json">{`{
          "@context": "http://schema.org",
          "@type": "Person",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "${site_url}"
          },
          "name": "Danielle Morales",
          "description": "${site_desc}"
        }`}</script>
      </Helmet>

      {/* Full project included here: */}
      {children}
    </Fragment>
  )
}

export default Layout